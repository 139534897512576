import './Footers.css'

export const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-title">
       © 2024 Dariel Guerra
      </div>
      {/*Dariel Guerra 2024 © */}
    </div>
  )
}