import React from 'react';
import ReactDOM from 'react-dom/client';
import './global-styles/NormalizeCSS.css';
import './global-styles/index.css';
import './global-styles/modal-global-styles.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

