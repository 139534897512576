import './Footers.css'

export const FooterHome = () => {
  return (
    <div className="footer-home">
      <div className="footer-title">
        © 2024 Dariel Guerra
      </div>
      {/*Dariel Guerra 2024 © */}
    </div>
  )
}