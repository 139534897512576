import "../../components/flight-components/flight-modals/edit-flight-modal/EditFlightModal.css";

export const PlaneIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox="0 0 1340 605"
      version="1.1"
      className="plane-icon"
      xmlSpace="preserve"
    >
      <g transform="matrix(1,0,0,1,-1980.08,-976)">
        <g transform="matrix(1,0,0,1,384,8)">
          <path d="M2439.73,1244.49C2439.73,1244.49 2439.73,1244.49 2439.73,1244.49C2439.73,1244.49 2439.73,1244.49 2439.73,1244.49C2439.31,1244 2521.22,1013.57 2524.81,1003.45C2528.4,993.303 2574.43,968 2574.43,968C2574.43,968 2537.31,1257.17 2541.82,1260.09C2554.05,1268 2564.51,1288.45 2564.51,1288.45L2750.26,1250.16L2757.34,1271.43C2757.34,1271.43 2552.66,1315.9 2553.17,1315.39C2553.55,1314.99 2530.03,1332.88 2519.14,1341.16C2515.87,1343.65 2513.74,1345.27 2513.74,1345.27L2936,1332.05L2936,1357.92L2689.28,1379.19C2689.28,1379.19 2684.2,1416.11 2672.27,1418.89C2660.34,1421.67 2655.26,1384.86 2655.26,1384.86C2655.26,1384.86 2587.37,1389.54 2587.2,1390.54C2579.37,1435.34 2569.26,1435.86 2564.51,1435.91C2545.16,1436.1 2541.82,1396.21 2541.82,1396.21L2468.09,1400.46L2439.73,1457.18L2404.08,1465.92C2404.08,1465.92 2409.38,1480.28 2411.38,1492.62C2413.31,1504.59 2371.24,1574.53 2303.62,1572.03C2219.38,1568.91 2246.9,1469.94 2246.9,1469.94C2246.9,1469.94 2060.41,1475.05 2059.74,1475.61C2037.14,1494.54 1925.08,1544.08 1912.27,1541.12C1899.47,1538.16 1901.78,1492.48 1899.29,1492.62C1890.13,1493.15 1766,1497.78 1716.6,1492.62C1673.38,1488.11 1596.14,1430.56 1596.08,1393.37C1596.04,1370.27 1621.32,1331.95 1656.28,1296C1695.19,1255.98 1746.12,1218.9 1787.5,1209.04C1865.93,1190.35 2438.15,1244.34 2439.73,1244.49ZM1660.56,1300.62L1676,1307.74L1712,1278.07L1688.28,1274.97L1660.56,1300.62ZM1712,1278.07L1676,1307.74L1768,1316.54L1779.66,1288.61L1712,1278.07Z" />
        </g>
      </g>
    </svg>
  );
};
